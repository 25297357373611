// AUTOMATICALLY GENERATED. DO NOT EDIT

import * as Types from '../../../../../graphql-codegen/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetAppointmentSlotsQueryVariables = Types.Exact<{
  user_ids: Array<Types.InputMaybe<Types.Scalars['ID']>> | Types.InputMaybe<Types.Scalars['ID']>;
  start_span: Types.Scalars['DateTime'];
  end_span: Types.Scalars['DateTime'];
  slot_increment?: Types.InputMaybe<Types.Scalars['Int']>;
  kind: Types.AppointmentKindEnum;
  medium: Types.AppointmentMediumEnum;
  upcoming_slots_only?: Types.InputMaybe<Types.Scalars['Boolean']>;
  upcoming_slots_count?: Types.InputMaybe<Types.Scalars['Int']>;
}>;

export type GetAppointmentSlotsQuery = {
  __typename?: 'Query';
  appointment_slots?: {
    __typename?: 'FetchAppointmentSlotsOutput';
    available: Array<any | null>;
    admin_available: Array<any | null>;
    blocked: Array<any | null>;
  } | null;
};

export const GetAppointmentSlotsDocument = gql`
  query getAppointmentSlots(
    $user_ids: [ID]!
    $start_span: DateTime!
    $end_span: DateTime!
    $slot_increment: Int = 30
    $kind: AppointmentKindEnum!
    $medium: AppointmentMediumEnum!
    $upcoming_slots_only: Boolean
    $upcoming_slots_count: Int = 3
  ) {
    appointment_slots(
      user_ids: $user_ids
      start_span: $start_span
      end_span: $end_span
      slot_increment: $slot_increment
      kind: $kind
      medium: $medium
      upcoming_slots_only: $upcoming_slots_only
      upcoming_slots_count: $upcoming_slots_count
    ) {
      available
      admin_available
      blocked
    }
  }
`;

/**
 * __useGetAppointmentSlotsQuery__
 *
 * To run a query within a React component, call `useGetAppointmentSlotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAppointmentSlotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAppointmentSlotsQuery({
 *   variables: {
 *      user_ids: // value for 'user_ids'
 *      start_span: // value for 'start_span'
 *      end_span: // value for 'end_span'
 *      slot_increment: // value for 'slot_increment'
 *      kind: // value for 'kind'
 *      medium: // value for 'medium'
 *      upcoming_slots_only: // value for 'upcoming_slots_only'
 *      upcoming_slots_count: // value for 'upcoming_slots_count'
 *   },
 * });
 */
export function useGetAppointmentSlotsQuery(
  baseOptions: Apollo.QueryHookOptions<GetAppointmentSlotsQuery, GetAppointmentSlotsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAppointmentSlotsQuery, GetAppointmentSlotsQueryVariables>(
    GetAppointmentSlotsDocument,
    options
  );
}
export function useGetAppointmentSlotsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAppointmentSlotsQuery, GetAppointmentSlotsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAppointmentSlotsQuery, GetAppointmentSlotsQueryVariables>(
    GetAppointmentSlotsDocument,
    options
  );
}
export type GetAppointmentSlotsQueryHookResult = ReturnType<typeof useGetAppointmentSlotsQuery>;
export type GetAppointmentSlotsLazyQueryHookResult = ReturnType<typeof useGetAppointmentSlotsLazyQuery>;
export type GetAppointmentSlotsQueryResult = Apollo.QueryResult<
  GetAppointmentSlotsQuery,
  GetAppointmentSlotsQueryVariables
>;
