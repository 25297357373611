export const SCHEDULING_ACCESS = [
  { label: 'US Scheduling', value: 'us_scheduling' },
  { label: 'International CN Scheduling', value: 'international_cn_scheduling' },
  { label: 'International No Scheduling', value: 'international_no_scheduling' },
  { label: 'International Full Scheduling', value: 'international_scheduling' },
];

export const PLAN_YEAR_VALUES = {
  single_year: '1_Year',
  custom: 'Custom',
  initial: '',
};

export const PLAN_YEAR_OPTIONS = [
  { label: '1 Year', value: '1_Year' },
  { label: 'Custom', value: 'Custom' },
];

export const PLAN_YEAR_STATUS = {
  active: 'ACTIVE',
  preLaunch: 'PRE_LAUNCH',
  inactive: 'INACTIVE',
};

export const PLAN_YEAR_STATUS_DISPLAY = {
  [PLAN_YEAR_STATUS.active]: 'Active',
  [PLAN_YEAR_STATUS.preLaunch]: 'Pre-launch',
  [PLAN_YEAR_STATUS.inactive]: 'Inactive',
};

// These should match the statues in rotom/app/models/contract_term.rb so that self.by_status() can be used correctly
export const PLAN_YEAR_STATUS_FILTERS = Object.values(PLAN_YEAR_STATUS_DISPLAY);

export const PLAN_YEAR_ACTION = {
  update: 'update',
  create: 'create',
};

export const APPOINTMENT_TYPES = [
  { label: 'Basic Visits', value: 'basic' },
  { label: 'Med Management Visits', value: 'specialist', isDisabled: true },
  { label: 'Coaching Visit', value: 'coaching', isDisabled: true },
];

export const VISIT_TYPE_ROW_ORDER = ['coaching', 'basic', 'specialist'];
