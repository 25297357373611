import { combineReducers } from 'redux';

import form from './form';
import modal from './modal';
import notifications from './notifications';
import table from './table';

const combined = combineReducers({
  form,
  modal,
  notifications,
  table,
});

const rootReducer = (state, action) => combined(state, action);

export { form, modal, notifications, table };
export default rootReducer;
