const routes = {
  CareNavigatorsBrowseDetail: {
    to: '/apps/Browse/care_navigators/[uid]',
    as: '/browse/care_navigators/:uid',
  },
  TherapistsBrowseDetail: {
    to: '/apps/Browse/therapists/[uid]',
    as: '/browse/therapists/:uid',
  },
  PhysiciansBrowseDetail: {
    to: '/apps/Browse/physicians/[uid]',
    as: '/browse/physicians/:uid',
  },
  CoachesBrowseDetail: {
    to: '/apps/Browse/coaches/[uid]',
    as: '/browse/coaches/:uid',
  },
};

export default routes;
