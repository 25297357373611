//

import React, { Component } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import FocusTrap from 'focus-trap-react';
import { Icon, VErrorIcon, VxIcon } from '@springcare/sh-component-library';

import styles from './styles.module.scss';

import { dismissNotification, mountNotification, removeNotification } from '../../../actions';

class Notification extends Component {
  componentDidMount() {
    setTimeout(() => {
      // to ensure the proper height is calculated
      this.forceUpdate();
      this.props.mount(this.props.timestamp);
    }, 50);
  }

  componentWillUnmount() {
    if (!this.props.out) {
      this.clearNotification();
    }
  }

  clearNotification = () => {
    this.props.dismiss(this.props.timestamp);
    window.setTimeout(() => {
      this.props.remove(this.props.timestamp);
    }, 1500);
  };

  notification = React.createRef();

  heightStyles = () => {
    if (!this.props.mounted) {
      return {};
    }

    const maxHeight = this.notification ? this.notification.offsetHeight : 0;

    return this.props.out ? { maxHeight: 0 } : { maxHeight };
  };

  icon = () => {
    switch (this.props.color) {
      case 'error':
        return (
          <div className={styles.leftIcon}>
            <Icon as={VErrorIcon} />
          </div>
        );

      case 'success':
      case 'info':
      case 'warn':
      default:
        return null;
    }
  };

  render() {
    return (
      <FocusTrap focusTrapOptions={{ clickOutsideDeactivates: true }}>
        <div
          style={this.heightStyles()}
          role={this.props.color === 'error' ? 'alert' : 'status'}
          className={classnames(styles.notification, styles[this.props.color], {
            [styles.mounted]: this.props.mounted,
            [styles.out]: this.props.out,
          })}
        >
          <div ref={(notification) => (this.notification = notification)} className={styles.content}>
            <div className={styles.textContainer}>
              {this.icon()}
              <div className={styles.text} data-cy="notification" tabIndex="0">
                {this.props.text}
              </div>
            </div>
            <div data-cy="close" className={styles.closeIcon}>
              <Icon as={VxIcon} onClick={this.clearNotification} />
            </div>
          </div>
        </div>
      </FocusTrap>
    );
  }
}

const Notifications = (props) => {
  const open = props.notifications.filter((n) => !n.out && n.mounted).length;
  return (
    <section
      className={classnames(styles.notificationWrapper, {
        [styles.empty]: open === 0,
      })}
    >
      {props.notifications.map((n) => (
        <Notification
          key={n.timestamp}
          color={n.color}
          mounted={n.mounted}
          out={n.out}
          text={n.text}
          timestamp={n.timestamp}
          dismiss={props.dismissNotification}
          mount={props.mountNotification}
          remove={props.removeNotification}
        />
      ))}
    </section>
  );
};

export default connect(
  (state) => ({
    notifications: state.notifications,
  }),
  { dismissNotification, mountNotification, removeNotification }
)(Notifications);
