import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useLDClient } from 'launchdarkly-react-client-sdk';

import { setUserIdentifiers } from 'actions/auth/actions';
import { integrationsIdentify, useIdentitySettersForIntegrations } from 'utils/integrations/integrations';
import { trackAppEvent, useAndInitializeUserTracking } from 'utils/userTracking';

import type { AdminUser } from 'modules/Admin/shared/context/AdminUserContext';
import { useRouter } from 'next/router';
import { FLAGS, useFeatureFlag } from 'utils/launchdarkly';
import { Maintenance } from 'modules/shared/components/Maintenance';

interface IReduxState {
  auth: {
    initialized: boolean;
    userIdentifiers: {
      user: AdminUser;
    };
  };
}

interface IProps {
  children: React.ReactNode;
  initialized: boolean;
  setUserIdentifiers: (data: AdminUser) => any;
  user: AdminUser;
  userIdentifiers: {
    user: AdminUser;
  };
}

const Initialize = (props: IProps) => {
  const userIdentifiers = props.userIdentifiers;
  const router = useRouter();

  const ldClient = useLDClient();

  useAndInitializeUserTracking();
  useIdentitySettersForIntegrations(userIdentifiers?.user);

  useEffect(() => {
    if (!ldClient) return;

    integrationsIdentify(props.userIdentifiers, {
      ldClient,
    });
  }, [userIdentifiers, ldClient]);

  const handleRouteChange = (newRoute) => {
    trackAppEvent.pageViewed(newRoute);
  };

  const showMaintenancePage = useFeatureFlag(FLAGS.MAINTENANCE_MODE);

  useEffect(() => {
    //Track landing page
    handleRouteChange(router.asPath);

    // When the component is mounted, subscribe to router changes
    // and log those page views
    // router passes the new asPath to the event handler
    router.events.on('routeChangeComplete', handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);

  return <>{ldClient && showMaintenancePage ? <Maintenance /> : <>{props.children}</>}</>;
};

export { Initialize };
export default connect(
  (state: IReduxState) => ({
    initialized: state?.auth?.initialized,
    userIdentifiers: state?.auth?.userIdentifiers,
    user: state?.auth?.userIdentifiers?.user,
  }),
  { setUserIdentifiers }
)(Initialize);
