import React, { useEffect } from 'react';
import classnames from 'classnames';
import { SideMenuItems } from 'components/Navigation/shared/SideMenuItems/SideMenuItems';
import styles from './styles.module.scss';

export const MobileSideMenu = ({ open }) => {
  useEffect(() => {
    const bodyElement = document.querySelector('body');

    if (bodyElement) {
      bodyElement.style.overflow = open ? 'hidden' : 'unset';
    }
  }, [open]);

  return (
    <nav
      aria-hidden={!open}
      className={classnames(styles.mobileSideMenu, { [styles.mobileSideMenuOpen]: open })}
      data-testid="mobile-side-menu"
    >
      <SideMenuItems showLinkText={true} />
    </nav>
  );
};
