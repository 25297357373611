import React from 'react';
import Anchor from 'next/link';
import classnames from 'classnames';
import routes from 'routes';

import styles from './styles.module.scss';

type LinkProps = {
  alias?: string;
  as?: string;
  children: React.ReactNode;
  className?: string;
  dataCy?: string;
  disabled?: boolean;
  queries?: Query;
  rel?: string;
  target?: string;
  to?: string;
  onClick?: () => void;
  dataTestId?: string;
};

type Query = {
  id?: string;
  uid?: string;
};

function constructPath(as = '', queries) {
  let path = as;
  //
  // Find all ':' in the path and replace with a query value.
  for (const query in queries) {
    path = path.replace(`:${query}`, `${queries[query]}`);
  }

  if (path.includes(':')) {
    console.error(
      'Link path still includes queries.  Please ensure you provide a replacement for all parameters.',
      path,
      queries
    );
  }

  return path;
}

const Link = (props: LinkProps) => {
  const classes = props.className || styles.default;
  const to = props.alias ? routes[props.alias].to : props.to;

  if (props.alias || props.as) {
    let as = props.alias ? routes[props.alias].as : props.as;

    if (!as) {
      as = '';

      if (props.alias !== 'PublicIndex') {
        console.error('Link "as" is empty.  Expected a value, recieved:', as, 'to', props.to, 'alias', props.alias);
      }
    }

    // If there are parameters in the URL, replace them
    // with query values before rendering the link.
    const asPath = as.includes(':') ? constructPath(as, props.queries) : as;
    // const href = to.includes(':') ? constructPath(to, props.queries) : to;

    let href;

    if (props.queries) {
      href = { pathname: to, query: { ...props.queries } };
    } else {
      href = to;
    }

    return (
      <Anchor href={href} as={asPath}>
        <a
          target={props.target}
          className={classnames(classes, {
            [styles.disabled]: props.disabled,
          })}
          data-cy={props.dataCy ? props.dataCy + '-link' : ''}
          onClick={props.onClick}
          data-testid={props.dataTestId}
        >
          {props.children}
        </a>
      </Anchor>
    );
  }

  let target;
  let rel;

  if (props.to && props.to.includes('http')) {
    target = '_blank';
    rel = 'noopener noreferrer';
  }

  return (
    <Anchor href={{ pathname: props.to, query: { ...props.queries } }}>
      <a
        rel={rel || props.rel}
        target={target || props.target}
        className={classnames(classes, {
          [styles.disabled]: props.disabled,
        })}
        onClick={props.onClick}
      >
        {props.children}
      </a>
    </Anchor>
  );
};

export default Link;
