import React from 'react';
import classnames from 'classnames';
import { Icon, ChevronRightIcon, ChevronLeftIcon } from '@springcare/sh-component-library';
import { useToggle } from 'components/hooks';

import styles from './styles.module.scss';

import { SideMenuItems } from '../shared/SideMenuItems/SideMenuItems';

interface SideMenuProps {
  className?: string;
  callback?: (data: object) => any;
}

export const SideMenu = ({ className, callback = () => {}, ...rest }: SideMenuProps) => {
  const [open, toggleOpen] = useToggle(true);

  const toggleOpenWithCallback = () => {
    callback({ open: !open });
    return toggleOpen();
  };

  return (
    <nav
      data-testid="nav-side-menu"
      className={classnames(styles.sideMenu, className, { [styles.open]: open })}
      {...rest}
    >
      <SideMenuItems showLinkText={open} />
      <button className={styles.toggleButton} data-testid="toggle-side-button" onClick={toggleOpenWithCallback}>
        {open ? <Icon as={ChevronLeftIcon} /> : <Icon as={ChevronRightIcon} />}
      </button>
    </nav>
  );
};
