/**
 * Enums for passing in Appointment Billing
 * Status where necessary.
 */

export const AppointmentBillingStatus = Object.freeze({
  ActionNeeded: 'ACTION_NEEDED',
  NoActionNeeded: 'NO_ACTION_NEEDED',
  BillToCustomer: 'BILL_TO_CUSTOMER',
  NeedsEligibilityVerification: 'NEEDS_ELIGIBILITY_VERIFICATION',
  NeedsCostCommunication: 'NEEDS_COST_COMMUNICATION',
  NeedsCoding: 'NEEDS_CODING',
  NeedsBilling: 'NEEDS_BILLING',
  NeedsInvoicing: 'NEEDS_INVOICING',
  Completed: 'COMPLETED',
});
