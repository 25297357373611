// Based on packages/constants/src/languages.js
// We display the English versions. See CGX-352 for reasoning
// NOTE: Use smeargle's LanguageSelect and constants/src/languages when you can instead.
// Build an englishName prop into LanguageSelect to support this use case.
export const languages = [
  {
    label: 'English',
    value: 'English',
  },
  {
    label: 'Spanish',
    value: 'Spanish',
  },
  {
    label: 'French',
    value: 'French',
  },
  {
    label: 'Portuguese',
    value: 'Portuguese',
  },
  {
    label: 'German',
    value: 'German',
  },
  {
    label: 'Dutch',
    value: 'Dutch',
  },
  {
    label: 'Polish',
    value: 'Polish',
  },
  {
    label: 'Japanese',
    value: 'Japanese',
  },
  {
    label: 'Hindi',
    value: 'Hindi',
  },
  {
    label: 'Canadian French',
    value: 'Canadian French',
  },
  {
    label: 'Czech',
    value: 'Czech',
  },
  {
    label: 'Arabic',
    value: 'Arabic',
  },
  {
    label: 'Hungarian',
    value: 'Hungarian',
  },
  {
    label: 'Indonesian',
    value: 'Indonesian',
  },
  {
    label: 'Hebrew',
    value: 'Hebrew',
  },
  {
    label: 'Italian',
    value: 'Italian',
  },
  {
    label: 'Malay',
    value: 'Malay',
  },
  {
    label: 'Filipino',
    value: 'Filipino',
  },
  {
    label: 'European Portuguese',
    value: 'European Portuguese',
  },
  {
    label: 'Romanian',
    value: 'Romanian',
  },
  {
    label: 'Simplified Mandarin',
    value: 'Simplified Mandarin',
  },
  {
    label: 'Korean',
    value: 'Korean',
  },
  {
    label: 'Greek',
    value: 'Greek',
  },
  {
    label: 'Turkish',
    value: 'Turkish',
  },
];
