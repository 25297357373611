import { gql } from '@apollo/client';

import paging from '../../fragments/paging';

const getUserAppointmentsOnDate = gql`
  query getUserAppointmentsOnDate($user_id: ID!, $on_date: DateTime!) {
    user(id: $user_id) {
      id
      calendar {
        appointments(on_date: $on_date) {
          data {
            id
            start_at
            end_at
            duration
          }
          paging {
            ...paging
          }
        }
      }
    }
  }
  ${paging}
`;

export default getUserAppointmentsOnDate;
