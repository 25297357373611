import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useSyncPreviousValue } from 'components/hooks';

/**
 * useUrlQueryParams takes in an object and watches for changes. When there is change in the
 * state it handles updating the URL with those changes.
 * @param state
 */
export const useUrlQueryParams = (state, withQueryParams) => {
  const router = useRouter();

  const currentState = JSON.stringify(state);
  const prevState = useSyncPreviousValue(currentState);

  useEffect(() => {
    if (withQueryParams && prevState !== currentState) {
      router.replace({ query: state });
    }
  }, [currentState]);
};
