// AUTOMATICALLY GENERATED. DO NOT EDIT

import * as Types from '../../../../../graphql-codegen/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCustomerQueryVariables = Types.Exact<{
  customerId: Types.Scalars['ID'];
  withCohorts?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;

export type GetCustomerQuery = {
  __typename?: 'Query';
  customer?: {
    __typename?: 'Customer';
    id: string;
    active: boolean;
    active_at?: any | null;
    cancellation_policy_gated_booking_enabled: boolean;
    care_navigation_contract_type: string;
    census_outreach_enabled: boolean;
    domain?: string | null;
    domains: Array<string | null>;
    employment_id_kind: string;
    enable_connected_benefits?: boolean | null;
    enable_member_no_show_policy?: boolean | null;
    inactive_at?: any | null;
    include_in_comparative_analytics: boolean;
    international_dependents?: number | null;
    international_employees?: number | null;
    launched: boolean;
    marketing_name?: string | null;
    name?: string | null;
    number_of_dependents?: number | null;
    number_of_employees: number;
    personal_id_kind: string;
    slug: string;
    sso_client_slug?: string | null;
    sso_client_slugs?: Array<string> | null;
    sso_slugs?: Array<string> | null;
    trial: boolean;
    us_dependents?: number | null;
    us_employees?: number | null;
    wpo_dependents?: number | null;
    wpo_employees?: number | null;
    channel_partner?: { __typename?: 'ChannelPartner'; id: string; name: string } | null;
    channel_partners?: Array<{ __typename?: 'ChannelPartner'; id: string; name: string }> | null;
    cohorts?: Array<{
      __typename?: 'Cohort';
      id: string;
      name?: string | null;
      country?: string | null;
      basic_visits_covered: number;
      specialist_visits_covered: number;
      dependents_allowed: boolean;
      members_count: number;
      contract_term?: {
        __typename?: 'ContractTerm';
        id: string;
        plan_name?: string | null;
        end_date: any;
        start_date: any;
        status: Types.ContractTermStatusEnumType;
      } | null;
    } | null>;
    customer_sites: Array<{ __typename?: 'CustomerSite'; id: string; name: string; external_site_id: string }>;
  } | null;
};

export const GetCustomerDocument = gql`
  query getCustomer($customerId: ID!, $withCohorts: Boolean = false) {
    customer(id: $customerId) {
      id
      active
      active_at
      cancellation_policy_gated_booking_enabled
      care_navigation_contract_type
      census_outreach_enabled
      channel_partner {
        id
        name
      }
      channel_partners {
        id
        name
      }
      cohorts @include(if: $withCohorts) {
        id
        name
        country
        basic_visits_covered
        specialist_visits_covered
        dependents_allowed
        members_count
        contract_term {
          id
          plan_name
          end_date
          start_date
          status
        }
      }
      customer_sites {
        id
        name
        external_site_id
      }
      domain
      domains
      employment_id_kind
      enable_connected_benefits
      enable_member_no_show_policy
      inactive_at
      include_in_comparative_analytics
      international_dependents
      international_employees
      launched
      marketing_name
      name
      number_of_dependents
      number_of_employees
      personal_id_kind
      slug
      sso_client_slug
      sso_client_slugs
      sso_slugs
      trial
      us_dependents
      us_employees
      wpo_dependents
      wpo_employees
    }
  }
`;

/**
 * __useGetCustomerQuery__
 *
 * To run a query within a React component, call `useGetCustomerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *      withCohorts: // value for 'withCohorts'
 *   },
 * });
 */
export function useGetCustomerQuery(baseOptions: Apollo.QueryHookOptions<GetCustomerQuery, GetCustomerQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCustomerQuery, GetCustomerQueryVariables>(GetCustomerDocument, options);
}
export function useGetCustomerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerQuery, GetCustomerQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCustomerQuery, GetCustomerQueryVariables>(GetCustomerDocument, options);
}
export type GetCustomerQueryHookResult = ReturnType<typeof useGetCustomerQuery>;
export type GetCustomerLazyQueryHookResult = ReturnType<typeof useGetCustomerLazyQuery>;
export type GetCustomerQueryResult = Apollo.QueryResult<GetCustomerQuery, GetCustomerQueryVariables>;
