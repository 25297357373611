import AdminRoutes from './AdminRoutes';
import BillingRoutes from './BillingRoutes';
import BrowseRoutes from './BrowseRoutes';
import PublicRoutes from './PublicRoutes';

export default {
  ...AdminRoutes,
  ...BillingRoutes,
  ...BrowseRoutes,
  ...PublicRoutes,
};
