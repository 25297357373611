import React from 'react';

import styles from './styles.module.scss';

// Display localStorage items from Magic Env Toggle first, then default to .env values if provided
const EnvDetails = () => {
  const [environment, apiUrl, authApiUrl, ehrApiUrl, atlasApiUrl] = [
    process.env.APP_ENV,
    process.env.APP_API,
    process.env.AUTH_API_DOMAIN,
    process.env.EHR_URL,
    process.env.ATLAS_API_DOMAIN,
  ];

  if (environment !== 'dev') {
    return null;
  }

  const rotom = localStorage.getItem('rotom');
  const diglet = localStorage.getItem('diglet');
  const ehr = localStorage.getItem('ehr');
  const atlas = localStorage.getItem('atlas');

  return (
    <div data-testid="env-details" role="complementary" className={styles.envDetails}>
      <p className={styles.envString}>
        Rotom: {rotom || apiUrl}
        <br />
        Auth: {diglet || authApiUrl}
        <br />
        EHR: {ehr || ehrApiUrl}
        <br />
        Atlas: {atlas || atlasApiUrl}
      </p>
    </div>
  );
};

export default EnvDetails;
