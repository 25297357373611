export const roles = [
  { label: 'Therapist', value: 'Therapist' },
  { label: 'Internist', value: 'Internist' },
  { label: 'Psychiatrist', value: 'Psychiatrist' },
  { label: 'Care Navigator', value: 'Care Navigator' },
  { label: 'Family Practitioner', value: 'Family Practitioner' },
  { label: 'Coach', value: 'Coach' },
  { label: 'Psychiatric Nurse Practitioner', value: 'Psychiatric Nurse Practitioner' },
  { label: 'Physician', value: 'Physician' },
  { label: 'Community Care Advocate', value: 'Care Advocate' },
  { label: 'Care Consultant', value: 'Care Consultant' },
  { label: 'Peer Recovery Specialist', value: 'Peer Recovery Specialist' },
  { label: 'Specialty Care Navigator', value: 'Specialty Care Navigator' },
];
