export const SpringLogoIcon = ({ size = 24, ...rest }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M10.9457 6.91988C8.97416 8.58728 7.33171 10.37 5.96207 12.1555C9.08946 9.99319 12.7653 8.8769 16.4298 9.12996C19.2085 9.32398 21.7481 10.3419 23.5762 11.4132C23.5762 11.4132 24.2793 10.2294 23.8743 4.82507C23.6577 1.91766 23.849 0 23.849 0C21.1631 0.165897 14.5287 3.88593 10.9513 6.91706L10.9457 6.91988Z"
        fill="#01382E"
      />
      <path
        d="M16.2985 10.983C12.0996 10.6905 7.86974 12.4479 4.68329 15.6225C6.64635 14.5202 9.23376 13.6683 12.3977 13.9157C16.6501 14.2531 19.6144 16.4941 21.3412 18.2965C22.3143 16.8681 22.9808 15.2148 23.2424 13.4321C21.524 12.2736 19.1812 11.1798 16.3013 10.9802L16.2985 10.983Z"
        fill="#01382E"
      />
      <path
        d="M12.2514 15.7743C6.54506 15.3216 2.9733 19.0388 2.01708 20.186C1.31961 21.0268 0.740249 21.9715 0.293076 22.9979C0.23964 23.1666 0.186208 23.3325 0.138397 23.4927C0.138397 23.4927 4.68325 24.4769 11.6889 23.7036C18.6918 22.9304 20.1655 19.7586 20.1655 19.7586C18.7031 18.1728 16.0791 16.0808 12.2486 15.7771L12.2514 15.7743Z"
        fill="#01382E"
      />
      <path
        d="M9.74242 5.50272C12.3861 3.26452 15.3672 1.41153 18.5537 0.00281143L11.6127 0.0478005C5.1582 0.0871659 -0.0419312 5.35651 0.000254871 11.8124L0.0424307 18.4736C1.8255 14.5118 4.8151 9.67545 9.73962 5.50272H9.74242Z"
        fill="#01382E"
      />
    </svg>
  );
};
