//
import findIndex from 'lodash/fp/findIndex';
import { setIn } from 'utils/immutability';

import {
  ADD_NOTIFICATION,
  DISMISS_NOTIFICATION,
  MOUNT_NOTIFICATION,
  REMOVE_NOTIFICATION,
} from '../../actions/notifications/types';

export const initialState = [];

const notifications = (state = [], action) => {
  switch (action.type) {
    case ADD_NOTIFICATION:
      return [
        ...state,
        {
          text: action.text,
          color: action.color,
          timestamp: action.timestamp,
        },
      ];

    case DISMISS_NOTIFICATION:
      return setIn([findIndex({ timestamp: action.timestamp }, state), 'out'], true, state);

    case MOUNT_NOTIFICATION:
      return setIn([findIndex({ timestamp: action.timestamp }, state), 'mounted'], true, state);

    case REMOVE_NOTIFICATION:
      return state.filter((n) => n.timestamp !== action.timestamp);

    default:
      return state;
  }
};

export default notifications;
