/* eslint-disable @typescript-eslint/ban-types, @typescript-eslint/ban-ts-comment */

import _has from 'lodash/has';
import { SH_MAGIC_ID_HEADER_KEY } from '../../../constants';
import { ddGlobalSessionId } from 'utils/telemetry';

import {
  Growlithe$GetMethod,
  Growlithe$IncludeValues,
  Growlithe$RequestOptions,
  Growlithe$UpdateMethod,
  Rotom$RequestParams,
} from './types';

function FetchApi(
  domain: string,
  base?: string
): {
  get: Growlithe$GetMethod;
  post: Growlithe$UpdateMethod;
  put: Growlithe$UpdateMethod;
  patch: Growlithe$UpdateMethod;
  delete: Growlithe$UpdateMethod;
  download: Growlithe$GetMethod;
} {
  /**
   * Handles adding any included relationships and keys for the api request
   *
   * @exmpale
   * let options = {
   *	include: {
   *		key: '3jfd'521840,
   *		collection: 'siblings',
   *		include: {
   *			key: 'a22g',
   *		},
   *	}
   * }
   * // Returns '/3jfd/siblings/a22g/'
   * // url = api.com/api/users/3jfd/siblings/a22g/
   *
   * @public
   * @param {object} options The (potentially nested) object of included relationships and keys
   * @returns {string} The url path with to append to the request
   */
  function includeValues(options: Growlithe$IncludeValues = {}): string {
    let path = '';

    if (options.collection) {
      path += `/${options.collection}`;
    }

    if (options.key) {
      path += `/${options.key}`;
    }

    if (_has(options, 'include')) {
      path += `${includeValues(options.include)}`;
    }

    return path;
  }

  /**
   * constructUrl
   *
   * @example
   * let collection = 'users'
   * let options = {
   *	key: '123'
   * }
   * // Returns '<domain>/users/123/'
   *
   * @public
   * @param   {string} collection The collection to request.  e.g. 'users'
   * @param   {object} options    Additional options that might add to the url as a path or queries
   * @returns {string}            The formatted url
   */
  function constructUrl(collection: string, options: Growlithe$RequestOptions): string {
    let url;

    if (base) {
      url = `${domain}/${base}/${collection}`;
    } else {
      url = `${domain}/${collection}`;
    }

    if (options.key) {
      url += `/${options.key}`;
    }

    if (_has(options, 'include')) {
      url += `/${includeValues(options.include)}`;
    }

    return url;
  }

  /**
   * Constructs the request parameters and headers
   *
   * @public
   * @param {object} options The options for this request
   * @param {string} method The request method (GET | POST | PUT etc)
   * @returns {object} The constructed parameters (including headers) for this request.
   */
  function constructParams(options: Growlithe$RequestOptions, method: string): Rotom$RequestParams {
    const params: Rotom$RequestParams = { method };

    params.headers = new Headers(
      Object.assign(
        {},
        {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          [SH_MAGIC_ID_HEADER_KEY]: ddGlobalSessionId.getSessionId(),
        },
        // eslint-disable-next-line no-undef
        options.headers as HeadersInit
      )
    );

    return params;
  }

  /**
   * Handles errors when requesting data from the api.
   * `options.onError` can be used to customize the response further
   * error handling, respectively
   *
   * @param  {Object} err          The error
   * @param  {Object} headers
   * @param  {number} status       The response status
   * @param  {string} collection   The collection eg. `campaigns`
   * @param  {Object} params       The request parameters
   * @param  {Object} [options={}] The options used to construct the request
   *
   * @return {Object}              The action
   */
  function handleError(
    err: {
      [key: string]: any;
    },
    headers: {
      [key: string]: string;
    },
    status: number,
    collection: string,
    params: Rotom$RequestParams,
    options: Growlithe$RequestOptions = {}
  ): void {
    if (options.onError && typeof options.onError === 'function') {
      options.onError(err);
    }
  }

  function parseBody(reader, decoder, filename, data, filetype, href): Promise<any> {
    // Read the results from the byte stream
    return reader.read().then((result: { value: any | null; done: any }): Promise<any> => {
      let newData = '';

      // Append the new data to the data stream.
      if (result.value) {
        newData = data + decoder.decode(result.value);
      }

      /**
       * When the data has been read in entirety, we want to open a download prompt.
       *
       * To do this, we create an <a> tag with the data of the file we were given.
       * Then, we automatically click it, which triggers the download in the browser.
       *
       * Finally, we resolve the promise to exit the recursive function.
       */
      if (result.done) {
        const a = document.createElement('a');
        a.textContent = 'download';
        a.download = `${filename}.${filetype}`;
        a.href = `${href},${encodeURIComponent(data)}`;
        document.body.appendChild(a);
        a.click();
        a.remove();

        return Promise.resolve();
      }

      // If there is still more data, call parse again.
      return parseBody(reader, decoder, filename, newData, filetype, href);
    });
  }

  function handleDownload(response, responseHeaders, filename = 'download', win: any, options): Promise<any> {
    const headers = Object.fromEntries(responseHeaders);

    console.log({ responseHeaders: JSON.stringify(responseHeaders), headers });

    if (headers['content-type'] === 'application/pdf') {
      if (response.blob) {
        return response.blob().then((blob) => {
          const newBlob = new Blob([blob], {
            type: 'application/pdf',
          });

          // onDownload indicates a direct file download, used in Cherrim
          if (options.onDownload) {
            return options.onDownload(newBlob);
          }

          // this block keeps Shaymin download use as-is
          // if using onDownload - do not pass window
          if (window && window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
            (window.navigator as any).msSaveOrOpenBlob(newBlob);
            return;
          }

          const data = window.URL.createObjectURL(newBlob);

          const a = win.document.createElement('a');
          a.href = data;
          win.document.body.appendChild(a);
          win.document.title = filename;
          a.click();

          a.remove();

          setTimeout(() => {
            // For FireFox, we must delay revoking otherwise the DL fails
            window.URL.revokeObjectURL(data);
          }, 900000);

          return Promise.resolve();
        });
      } else if (response.body) {
        const reader = response.body.getReader();
        const decoder = new TextDecoder();

        return parseBody(reader, decoder, filename, '', 'pdf', 'data:text/pdf;charset=utf-8');
      }
    }

    return Promise.reject();
  }

  /**
   * request
   *
   * @public
   * @param {string}   url        The request url
   * @param {object}   options    Additional options specified by the user
   * @param {object}   params     The request parameters
   * @param {string}   collection The collection being requested ('users', for example)
   *
   * @returns {Promise}
   */
  function request(
    url: string,
    options: Growlithe$RequestOptions,
    params: Rotom$RequestParams,
    collection: string
  ): Promise<any> {
    let status = 0;
    let responseHeaders = {};

    // Make the api request
    return fetch(url, params)
      .then((response) => {
        status = response.status;
        responseHeaders = response.headers;

        if (options.download) {
          return handleDownload(response, responseHeaders, options.filename, options.window, options);
        }

        if (status !== 204) {
          return response.json();
        }
      })
      .then(
        (res): Promise<any> => {
          // Resolve the pending request in the store.
          // @ts-ignore
          const headers = Object.fromEntries(responseHeaders);

          const payload = Object.assign({}, res); // clone the res

          // If the response is between 200 and 300, return a successful api response
          if (status >= 200 && status < 300) {
            return Promise.resolve({
              response: payload,
              status,
              headers,
            });
          }

          // Otherwise, return a error
          handleError(payload, headers, status, collection, params, options);
          return Promise.reject(payload);
        }, // If something went wrong outside of the response, handle that.
        (err): Promise<any> => {
          // Remove the pending request since it won't finish.
          handleError(err, {}, status, collection, params, options);
          return Promise.reject(err);
        }
      );
  }

  return {
    get:
      (collection: string, options: Growlithe$RequestOptions = {}): (() => {}) =>
      (): Promise<any> => {
        const url = constructUrl(collection, options);
        const params = constructParams(options, 'GET');
        return request(url, options, params, collection);
      },

    post:
      (
        collection: string,
        data: {
          [key: string]: unknown;
        },
        options: Growlithe$RequestOptions = {}
      ): (() => {}) =>
      (): Promise<any> => {
        const url = constructUrl(collection, options);

        // if data is FormData, cannot set Content-Type manually with 'application/json'
        // or override with 'multipart/form-data' in header
        // need to exclude Content-Type property so that the browser
        // can automatically detect and set the boundary and content type.
        // https://muffinman.io/uploading-files-using-fetch-multipart-form-data/
        let params;
        if (data instanceof FormData) {
          params = {
            method: 'POST',
            body: data,
            headers: new Headers(Object.assign({}, options.headers as any)),
          };
        } else {
          params = constructParams(options, 'POST');
          params.body = JSON.stringify(data);
        }

        return request(url, options, params, collection);
      },

    patch:
      (
        collection: string,
        data: {
          [key: string]: unknown;
        },
        options: Growlithe$RequestOptions = {}
      ): (() => {}) =>
      (): Promise<any> => {
        const url = constructUrl(collection, options);
        const params = constructParams(options, 'PATCH');
        params.body = JSON.stringify(data);

        return request(url, options, params, collection);
      },

    put:
      (
        collection: string,
        data: {
          [key: string]: unknown;
        },
        options: Growlithe$RequestOptions = {}
      ): (() => {}) =>
      (): Promise<any> => {
        const url = constructUrl(collection, options);
        const params = constructParams(options, 'PUT');
        params.body = JSON.stringify(data);

        return request(url, options, params, collection);
      },

    delete:
      (
        collection: string,
        data: {
          [key: string]: unknown;
        },
        options: Growlithe$RequestOptions = {}
      ): (() => {}) =>
      (): Promise<any> => {
        const url = constructUrl(collection, options);
        const params = constructParams(options, 'DELETE');
        params.body = JSON.stringify(data);
        return request(url, options, params, collection);
      },

    download:
      (collection: string, options: Growlithe$RequestOptions = {}): (() => {}) =>
      (): Promise<any> => {
        const url = constructUrl(collection, options);
        const params = constructParams(options, 'GET');

        options.download = true;

        return request(url, options, params, collection);
      },
  };
}

export default FetchApi;
