import { gql } from '@apollo/client';

import { userInfo } from '../../fragments/user';

const getUserInfo = gql`
  query getUserInfo($id: ID!) {
    user(id: $id) {
      ...userInfo
    }
  }
  ${userInfo}
`;

export default getUserInfo;
