// AUTOMATICALLY GENERATED. DO NOT EDIT

import * as Types from '../../../../../graphql-codegen/types';

import { gql } from '@apollo/client';
import { PagingFragmentFragmentDoc } from '../fragments/pagingFragment.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCustomersQueryVariables = Types.Exact<{
  active?: Types.InputMaybe<Types.Scalars['Boolean']>;
  launched?: Types.InputMaybe<Types.Scalars['Boolean']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']>;
  name?: Types.InputMaybe<Types.Scalars['String']>;
  care_navigation_contract_type?: Types.InputMaybe<Array<Types.Scalars['String']> | Types.Scalars['String']>;
  direct_employer_or_peo?: Types.InputMaybe<Types.Scalars['String']>;
  sort_by?: Types.InputMaybe<Array<Types.InputMaybe<Types.SortType>> | Types.InputMaybe<Types.SortType>>;
  channel_partner_id?: Types.InputMaybe<Types.Scalars['String']>;
  withCohorts?: Types.InputMaybe<Types.Scalars['Boolean']>;
}>;

export type GetCustomersQuery = {
  __typename?: 'Query';
  customers?: {
    __typename?: 'PaginatedCustomers';
    data: Array<{
      __typename?: 'Customer';
      active: boolean;
      care_navigation_contract_type: string;
      domains: Array<string | null>;
      id: string;
      launched: boolean;
      name?: string | null;
      slug: string;
      channel_partners?: Array<{ __typename?: 'ChannelPartner'; id: string; name: string }> | null;
      cohorts?: Array<{
        __typename?: 'Cohort';
        id: string;
        name?: string | null;
        country?: string | null;
        has_census_ingestions: boolean;
        member_termination_enabled: boolean;
      } | null>;
      customer_sites: Array<{ __typename?: 'CustomerSite'; id: string; name: string; external_site_id: string }>;
    } | null>;
    paging: {
      __typename?: 'PagingData';
      page?: number | null;
      pages?: number | null;
      total?: number | null;
      limit?: number | null;
    };
  } | null;
};

export const GetCustomersDocument = gql`
  query getCustomers(
    $active: Boolean
    $launched: Boolean
    $offset: Int
    $limit: Int
    $name: String
    $care_navigation_contract_type: [String!]
    $direct_employer_or_peo: String
    $sort_by: [SortType] = [{ column: "customers.created_at", direction: "desc" }]
    $channel_partner_id: String
    $withCohorts: Boolean = false
  ) {
    customers(
      active: $active
      launched: $launched
      offset: $offset
      limit: $limit
      name: $name
      direct_employer_or_peo: $direct_employer_or_peo
      care_navigation_contract_type: $care_navigation_contract_type
      sort_by: $sort_by
      channel_partner_id: $channel_partner_id
    ) {
      data {
        active
        care_navigation_contract_type
        channel_partners {
          id
          name
        }
        cohorts @include(if: $withCohorts) {
          id
          name
          country
          has_census_ingestions
          member_termination_enabled
        }
        customer_sites {
          id
          name
          external_site_id
        }
        domains
        id
        launched
        name
        slug
      }
      paging {
        ...pagingFragment
      }
    }
  }
  ${PagingFragmentFragmentDoc}
`;

/**
 * __useGetCustomersQuery__
 *
 * To run a query within a React component, call `useGetCustomersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomersQuery({
 *   variables: {
 *      active: // value for 'active'
 *      launched: // value for 'launched'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      name: // value for 'name'
 *      care_navigation_contract_type: // value for 'care_navigation_contract_type'
 *      direct_employer_or_peo: // value for 'direct_employer_or_peo'
 *      sort_by: // value for 'sort_by'
 *      channel_partner_id: // value for 'channel_partner_id'
 *      withCohorts: // value for 'withCohorts'
 *   },
 * });
 */
export function useGetCustomersQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCustomersQuery, GetCustomersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCustomersQuery, GetCustomersQueryVariables>(GetCustomersDocument, options);
}
export function useGetCustomersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCustomersQuery, GetCustomersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCustomersQuery, GetCustomersQueryVariables>(GetCustomersDocument, options);
}
export type GetCustomersQueryHookResult = ReturnType<typeof useGetCustomersQuery>;
export type GetCustomersLazyQueryHookResult = ReturnType<typeof useGetCustomersLazyQuery>;
export type GetCustomersQueryResult = Apollo.QueryResult<GetCustomersQuery, GetCustomersQueryVariables>;
