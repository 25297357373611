import { countries } from '@spring/constants';

export const countryOptions = countries.map((entry) => {
  return {
    label: entry.displayName,
    value: entry.iso2.toUpperCase(),
  };
});

// Will be removed in CGX-428
export const countryOptionsISO3 = countries.map((entry) => {
  return {
    label: entry.displayName,
    value: entry.iso3.toUpperCase(),
  };
});

// List of currently supported licensed countries for caregivers. Values in ISO2 format
// NOTE: Intended as a temporary hard coded list until CGX-398 is done
export const hardcodedLicensedCountries = [
  { label: 'Australia', value: 'AU' },
  { label: 'Brazil', value: 'BR' },
  { label: 'Canada', value: 'CA' },
  { label: 'France', value: 'FR' },
  { label: 'Germany', value: 'DE' },
  { label: 'India', value: 'IN' },
  { label: 'Ireland', value: 'IE' },
  { label: 'Sweden', value: 'SE' },
  { label: 'United Kingdom', value: 'GB' },
  { label: 'United States of America', value: 'US' },
  { label: 'Argentina', value: 'AR' },
  { label: 'Chile', value: 'CL' },
  { label: 'Colombia', value: 'CO' },
  { label: 'Costa Rica', value: 'CR' },
  { label: 'Mexico', value: 'MX' },
  { label: 'Spain', value: 'ES' },
  { label: 'Dominican Republic', value: 'DO' },
  { label: 'Guatemala', value: 'GT' },
  { label: 'El Salvador', value: 'SV' },
  { label: 'New Zealand', value: 'NZ' },
  { label: 'Panama', value: 'PA' },
  { label: 'Peru', value: 'PE' },
  { label: 'Ecuador', value: 'EC' },
  { label: 'Netherlands', value: 'NL' },
  { label: 'Switzerland', value: 'CH' },
  { label: 'Poland', value: 'PL' },
  { label: 'South Africa', value: 'ZA' },
  { label: 'Japan', value: 'JP' },
  { label: 'Austria', value: 'AT' },
  { label: 'Belgium', value: 'BE' },
  { label: 'Czechia', value: 'CZ' },
  { label: 'Egypt', value: 'EG' },
  { label: 'Finland', value: 'FI' },
  { label: 'Hungary', value: 'HU' },
  { label: 'Indonesia', value: 'ID' },
  { label: 'Israel', value: 'IL' },
  { label: 'Italy', value: 'IT' },
  { label: 'Malaysia', value: 'MY' },
  { label: 'Norway', value: 'NO' },
  { label: 'Philippines', value: 'PH' },
  { label: 'Portugal', value: 'PT' },
  { label: 'Romania', value: 'RO' },
  { label: 'Singapore', value: 'SG' },
  { label: 'South Korea', value: 'KR' },
  { label: 'United Arab Emirates', value: 'AE' },
  { label: 'Denmark', value: 'DK' },
  { label: 'Cyprus', value: 'CY' },
  { label: 'Georgia', value: 'GE' },
  { label: 'Greece', value: 'GR' },
  { label: 'Jordan', value: 'JO' },
  { label: 'Serbia', value: 'RS' },
  { label: 'Turkey', value: 'TR' },
  { label: 'Bulgaria', value: 'BG' },
  { label: 'Estonia', value: 'EE' },
  { label: 'Croatia', value: 'HR' },
  { label: 'Kenya', value: 'KE' },
  { label: 'Kuwait', value: 'KW' },
  { label: 'Kazakhstan', value: 'KZ' },
  { label: 'Sri Lanka', value: 'LK' },
  { label: 'Latvia', value: 'LV' },
  { label: 'Luxembourg', value: 'LU' },
  { label: 'Lithuania', value: 'LT' },
  { label: 'Morocco', value: 'MA' },
  { label: 'Nigeria', value: 'NG' },
  { label: 'Slovenia', value: 'SI' },
  { label: 'Slovakia', value: 'SK' },
  { label: 'Ukraine', value: 'UA' },
  { label: 'Lebanon', value: 'LB' },
  { label: 'Oman', value: 'OM' },
  { label: 'Pakistan', value: 'PK' },
  { label: 'Trinidad and Tobago', value: 'TT' },
  { label: 'Uruguay', value: 'UY' },
  { label: 'Bahrain', value: 'BH' },
  { label: 'Armenia', value: 'AM' },
  { label: 'Malta', value: 'MT' },
  { label: "Côte d'Ivoire", value: 'CI' },
  { label: 'Brunei', value: 'BN' },
  { label: 'Bangladesh', value: 'BD' },
  { label: 'Macao SAR', value: 'MO' },
  { label: 'North Macedonia', value: 'MK' },
];

export const setCityState = (country) => {
  const address = [
    { country_code: 'us', office_city: 'New York City', office_state: 'NY', home_state: 'NY' },
    { country_code: 'fr', office_city: 'Paris', office_state: 'PA', home_state: 'PA' },
    { country_code: 'au', office_city: 'Melbourne', office_state: 'ME', home_state: 'ME' },
    { country_code: 'ca', office_city: 'Toronto', office_state: 'TO', home_state: 'TO' },
    { country_code: 'br', office_city: 'Rio de Janeiro', office_state: 'RJ', home_state: 'RJ' },
    { country_code: 'de', office_city: 'Berlin', office_state: 'BE', home_state: 'BE' },
    { country_code: 'in', office_city: 'Delhi', office_state: 'ND', home_state: 'ND' },
    { country_code: 'ie', office_city: 'Dublin', office_state: 'DB', home_state: 'DB' },
    { country_code: 'se', office_city: 'Dalarna', office_state: 'DA', home_state: 'DA' },
    { country_code: 'gb', office_city: 'London', office_state: 'LD', home_state: 'LD' },
  ];

  return address.find((e) => e.country_code === country);
};
