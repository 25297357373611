import { gql } from '@apollo/client';

const userInfo = gql`
  fragment userInfo on User {
    id
    active
    created_at
    first_name
    last_name
    date_of_birth
    email
    phone
    roles
    time_zone
    member {
      id
    }
  }
`;

export default userInfo;
