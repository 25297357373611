import { useDispatch, useSelector } from 'react-redux';

// FIXME: Wrappers for the redux hooks. This allows us to decouple Redux from our component AND allows easy
// mocking in tests. Ideally, these custom wrappers have no logic and should be greatly simplified when
// we're able to better type our store and state. See https://react-redux.js.org/using-react-redux/usage-with-typescript
// For more details. Unfortunately, we do not use configureStore from the toolkit, which makes typing our store
// more complicated.

const useActionDispatch = () => {
  const dispatch = useDispatch();

  const appDispatch = (action) => {
    dispatch(action);
  };

  return { dispatch: appDispatch };
};

const useStateSelector = (selector: (state) => any, equalityFn?: () => any) => {
  return useSelector(selector, equalityFn);
};

export { useActionDispatch, useStateSelector };
