import { useLazyQuery } from '@apollo/client';
import { GetUserIdentifiersDocument } from '../graphql/queries/getUserIdentifiers.generated';

import type { LazyQueryHookOptions } from '@apollo/client';
import type {
  GetUserIdentifiersQuery,
  GetUserIdentifiersQueryVariables,
} from '../graphql/queries/getUserIdentifiers.generated';

export default (baseOptions?: LazyQueryHookOptions<GetUserIdentifiersQuery, GetUserIdentifiersQueryVariables>) => {
  return useLazyQuery<GetUserIdentifiersQuery, GetUserIdentifiersQueryVariables>(GetUserIdentifiersDocument, {
    ...baseOptions,
  });
};
