import { gql } from '@apollo/client';

const getUserIdentifiers = gql`
  query getUserIdentifiers($id: ID!) {
    user(id: $id) {
      id
      email
      created_at
      confirmed
      first_name
      last_name
    }
  }
`;

export default getUserIdentifiers;
