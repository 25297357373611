import { useState, useEffect } from 'react';
import { DateTime } from 'luxon';

import { useNotification } from 'modules/shared/hooks';

interface Options {
  mimeType: string;
  filename: string;
}

const useDownload = (opts: Options) => {
  const [downloadData, setDownloadData] = useState<string | null>(null);

  const notify = useNotification();

  useEffect(() => {
    if (downloadData) {
      const blob = URL.createObjectURL(new Blob([downloadData], { type: opts.mimeType || 'text/plain' }));

      const link = document.createElement('a');
      link.href = blob;
      link.setAttribute('download', `${opts.filename}_${DateTime.utc().toFormat('FFF')}`);

      document.body.appendChild(link);

      link.click();
      link.parentNode?.removeChild(link);

      setDownloadData(null);
      notify({
        description: 'CSV successfully created',
        status: 'success',
      });
    }
  }, [downloadData]);

  return {
    setDownloadData,
  };
};

export default useDownload;
