import React from 'react';
import { GetUserIdentifiersQueryResult } from '../graphql/queries/getUserIdentifiers.generated';

export type AdminUser = NonNullable<GetUserIdentifiersQueryResult['data']>['user'];

export type AdminUserContextType = {
  user: AdminUser;
  loading?: boolean;
};

export const AdminUserContext = React.createContext<AdminUserContextType>({ user: undefined, loading: true });
