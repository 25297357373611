import React from 'react';
import PropTypes from 'prop-types';
import App from 'next/app';
import Head from 'next/head';
import { Provider } from 'react-redux';
import { ApolloProvider } from '@apollo/client';
import Notifications from '@spring/smeargle/components/molecules/Notifications';
import { withLDProvider } from 'launchdarkly-react-client-sdk';

import Initialize from 'components/molecules/Initialize';
import withReduxStore from 'utils/redux/wrapper';
import initApollo from 'utils/apollo';
import ThemeProvider from 'design-system/theme';
import { initializeAndStartDD } from '../utils/datadog/datadog';

import { ErrorBoundary } from 'modules/shared/components/ErrorBoundary';

import '../styles/root.scss';
import { AdminUserContextProvider } from 'modules/Admin/shared/context/AdminUserContextProvider';

const apolloClient = initApollo();

class Spring extends App {
  componentDidMount() {
    localStorage.setItem('initialTarget', window.location.pathname);

    initializeAndStartDD(this.props.router);

    window.onbeforeunload = () => {
      localStorage.removeItem('initialTarget');
    };
  }

  render() {
    const { Component, pageProps, reduxStore, router } = this.props;

    // Use the layout defined at the page level, if available
    const getLayout = Component.getLayout ?? ((page) => page);
    return (
      <>
        <Head>
          <title>Spring Health</title>
          <meta content="width=device-width,initial-scale=1" name="viewport" />
        </Head>
        <ApolloProvider client={apolloClient}>
          <Provider store={reduxStore}>
            <ThemeProvider>
              <AdminUserContextProvider>
                {/* Wrap ErrorBoundary in Providers, mainly to grant access to ReduxStore due to PageBase */}
                <ErrorBoundary>
                  <Initialize>
                    <Notifications />
                    {getLayout(<Component url={{ query: router.query, pathname: router.pathname }} {...pageProps} />)}
                  </Initialize>
                </ErrorBoundary>
              </AdminUserContextProvider>
            </ThemeProvider>
          </Provider>
        </ApolloProvider>
      </>
    );
  }
}

Spring.propTypes = {
  Component: PropTypes.elementType,
  apolloClient: PropTypes.object,
  pageProps: PropTypes.object,
  reduxStore: PropTypes.object,
  router: PropTypes.object,
};

export default withLDProvider({
  clientSideID: process.env.LAUNCH_DARKLY_CLIENT_ID,
  options: {},
  reactOptions: {
    useCamelCaseFlagKeys: false,
  },
})(withReduxStore(Spring));
