import PropTypes from 'prop-types';
import React from 'react';
import Standard from '@spring/smeargle/components/layout/Containers/Standard/Standard';

import PageBase from 'components/layout/Page/PageBase/PageBase';

import styles from './styles.scss';

const LandingPageWrapper = ({ allowed = true, children }) => {
  return (
    <PageBase allowed={allowed}>
      <div className={styles.pageContent}>
        <Standard>{children}</Standard>
      </div>
    </PageBase>
  );
};

LandingPageWrapper.propTypes = {
  children: PropTypes.any,
};

export default LandingPageWrapper;
