import Auth from 'services/auth';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';
import Navigation from 'components/Navigation/Navigation';

import styles from './styles.scss';

class PageBase extends Component {
  static propTypes = {
    allowed: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    children: PropTypes.any,
    darkBg: PropTypes.any,
  };

  timeout = null;
  refreshTimeout = null;

  state = { allowed: 'pending' };
  static defaultProps = { allowed: true };

  componentDidMount() {
    if (this.props.allowed === true) {
      this.setAllowed(true);
    } else {
      this.checkPermission();
    }

    this.refreshTimeout = setTimeout(() => {
      // If their token expires in under 5 mins, optimistically refresh
      if (localStorage.access_token_expires_at - 300000 < Date.now()) {
        Auth.refreshAccessToken();
      }
    }, 30000);
  }

  checkPermission() {
    if (this.props.allowed === true) {
      this.setAllowed(true);
      return;
    }

    const check = this.props.allowed();

    if (check) {
      check.allowed.then((allowed) => {
        if (allowed === 'pending') {
          // Do nothing
        }

        if (allowed === true) {
          this.setAllowed(true);
        }

        if (allowed === false) {
          this.setAllowed(false);
        }
      });
    }
  }

  setAllowed = (allowed) => {
    this.setState({ allowed });
  };

  render() {
    if (this.state.allowed === 'pending' || this.state.allowed === false) {
      return null;
    }

    return (
      <Navigation>
        <div
          className={classnames(styles.pageBase, {
            [styles.dark]: this.props.darkBg,
          })}
        >
          {this.props.allowed && this.props.children}
        </div>
      </Navigation>
    );
  }
}

export default PageBase;

export { PageBase };
