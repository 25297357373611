/**
 * Enums for provider roles.
 */

export const ProviderRole = Object.freeze({
  CareNavigator: 'care_navigator',
  Therapist: 'therapist',
  MedicationManager: 'medication_manager',
  Coach: 'coach',
  PeerRecoverySpecialist: 'peer_recovery_specialist',
});

/**
 *
 * @param {string} providerRole enums/ProviderRole, eg ProviderRole.CareNavigator
 * @returns {boolean}
 */
export const isStateLicensedProvider = (providerRole) =>
  [ProviderRole.Therapist, ProviderRole.MedicationManager].includes(providerRole);
