/* eslint-disable @typescript-eslint/ban-ts-comment */

import FetchApi from 'services/api/fetch/fetchApi';
import without from 'lodash/without';
import LocalStorageUtils from 'utils/localStorage';
import CookieUtils from 'utils/cookies';
import union from 'lodash/union';
import { Operation } from '@apollo/client';
import { SH_MAGIC_ID_HEADER_KEY } from '../../constants';
import { ddGlobalSessionId } from 'utils/telemetry';

const AUTH_INITIALIZED = 'auth/INITIALIZED';
const AUTH_VERIFIED_TOKEN = 'auth/VERIFIED_TOKEN';
const LOGOUT = 'LOGOUT';

const generateRequestHeaders = () => ({
  ...LocalStorageUtils.getAuthorizationHeader(),
  [SH_MAGIC_ID_HEADER_KEY]: ddGlobalSessionId.getSessionId(),
});

export default class Auth {
  apiUrl;
  store;
  api;

  _pendingRequests: Operation[] = [];

  static MFA_DEVICE_ID_SMS = 2;
  static MFA_DEVICE_ID_APP = 3;
  private refreshAttempt: Promise<unknown> | null = null;

  constructor(api, reduxStore) {
    this.apiUrl = api;
    this.store = reduxStore;
    // @ts-ignore
    this.api = new FetchApi(api);
  }

  // REST functions for SSO client portal and API.
  // this function returns ALL rows from the DB in pages of 20
  // it take the page number and returns the 20 rows for that page
  getSSOClients(page) {
    return this.store
      .dispatch(
        this.api.get('v1/sso-clients?page=' + page, {
          headers: LocalStorageUtils.getAuthorizationHeader(),
        })
      )
      .then(({ status, response }) => {
        if (status === 200) {
          return response;
        }
      })
      .catch((err) => {
        throw err;
      });
  }

  // takes a search param and returns only the rows that have that client name
  getSSOClientBySlug(search_slug) {
    return this.store
      .dispatch(
        this.api.get('v1/sso-clients?search_slug=' + search_slug, {
          headers: LocalStorageUtils.getAuthorizationHeader(),
        })
      )
      .then(({ status, response }) => {
        if (status === 200) {
          return response;
        }
      })
      .catch((err) => {
        throw err;
      });
  }

  // this function returns ONLY THE REQUESTED CLIENT by ID
  // requries that a client ID be passed (row ID, primary key)
  getSSOClient(sso_client_id) {
    return fetch(this.apiUrl + '/v1/sso-clients/' + sso_client_id, {
      headers: generateRequestHeaders(),
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
      })
      .catch((err) => {
        throw err;
      });
  }

  // Regenerate an SSO client SP encryption data
  generateSSOClientSPEncryption(sso_client_id) {
    return fetch(this.apiUrl + '/v1/sso-clients/' + sso_client_id + '/generate-sp-encryption', {
      headers: generateRequestHeaders(),
      method: 'PUT',
    }).then((response) => {
      return response.status === 204;
    });
  }

  // this function makes a post request to DIGLET to create a NEW row in the DB
  // only the slug is required, returns errors if data does not pass validation
  // data is validated by DIGLET
  createSSOClient(sso_client_data) {
    return this.store.dispatch(
      this.api.post('v1/sso-clients', sso_client_data, {
        headers: LocalStorageUtils.getAuthorizationHeader(),
      })
    );
  }

  // this function makes a PUT request to DIGLET to update the row at the passed ID
  // requires the client ID (which should be present and correct if following the proper path by clicking the link generated by the GET request)
  // data validation will be handled by DIGLET
  updateSSOClient(sso_client_data, sso_client_id) {
    return this.store.dispatch(
      this.api.put('v1/sso-clients/' + sso_client_id, sso_client_data, {
        headers: LocalStorageUtils.getAuthorizationHeader(),
      })
    );
  }

  _clearSession() {
    LocalStorageUtils.clearHeaders();
    CookieUtils.clearHeaders();
    this.store.dispatch({ type: LOGOUT });
  }

  _setExpirationTime(accessExpiresIn) {
    const accessNow = new Date();

    accessNow.setSeconds(accessNow.getSeconds() + accessExpiresIn);
    const accessNowTime = accessNow.getTime();

    LocalStorageUtils.updateTokenExpiration(accessNowTime);
    CookieUtils.updateTokenExpiration(accessNowTime);
  }

  dispatchVerification(valid) {
    this.store.dispatch({
      type: AUTH_VERIFIED_TOKEN,
      verified: true,
      valid,
    });

    window.setTimeout(() => {
      this.store.dispatch({
        type: AUTH_INITIALIZED,
      });
    }, 1000);
  }

  async verifyToken() {
    let access_token;

    if (typeof window !== 'undefined') {
      access_token = localStorage.getItem('access_token');
    }

    if (!access_token) {
      this.dispatchVerification(false);
      LocalStorageUtils.clearHeaders();
      CookieUtils.clearHeaders();

      return;
    }

    try {
      this.validateAccessToken(access_token);
      return this.dispatchVerification(true);
    } catch (err) {
      return this.refreshAccessTokenV2();
    }
  }

  parseJwt(access_token) {
    try {
      let base64Url = access_token.split('.')[1];
      let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      let jsonPayload = decodeURIComponent(
        window
          .atob(base64)
          .split('')
          .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join('')
      );

      return JSON.parse(jsonPayload);
    } catch (error) {
      console.error('Error parsing JWT: ', access_token);
      return undefined;
    }
  }

  validateAccessToken(token) {
    const parsed_token = this.parseJwt(token);

    // Make sure it has an ID
    if (parsed_token === undefined || !parsed_token?.id) {
      throw Error('Token is malformed');
    }

    // Check for token to be expired
    if (!parsed_token?.expires || new Date(parsed_token.expires) <= new Date()) {
      throw Error('Token has expired');
    }

    return parsed_token;
  }

  /**
   * NOTE: There is a refactoring opportunity(ies) with refreshAccessTokenV2,
   * setRefreshedTokenHeaders, and refreshAccessToken.
   *
   * refreshAccessTokenV2 and setRefreshedTokenHeaders were
   * abstracted from verifyToken (above), but the logic is more or less the same in
   * refreshAccessToken (below), with small variance. This would involve digging into
   * handleTokenExpiration, which handles expired tokens when making GQL calls.
   *
   * See this bug ticket as well: https://springhealth.atlassian.net/browse/PFRM-851
   */

  async refreshAccessTokenV2() {
    try {
      const refresh_token = localStorage.getItem('refresh_token');

      // Do nothing. The user will be redirected to /sign_in to login again.
      if (!refresh_token) {
        this.dispatchVerification(false);
        LocalStorageUtils.clearHeaders();
        CookieUtils.clearHeaders();
      }

      const { response } = await this.createRefreshedAccessToken(refresh_token);

      this.setRefreshedTokenHeaders(response);

      return this.dispatchVerification(true);
    } catch (err) {
      this.dispatchVerification(false);
      LocalStorageUtils.clearHeaders();
      CookieUtils.clearHeaders();

      // Do nothing. The user will be redirected to /sign_in to login again.
      if (err?.message === 'The refresh token is invalid.') {
        return;
      }

      throw err;
    }
  }

  async createRefreshedAccessToken(token) {
    return this.store.dispatch(
      this.api.post('oauth/access_token', {
        refresh_token: token,
        grant_type: 'refresh_token',
        client_id: process.env.CLIENT_ID,
        client_secret: process.env.CLIENT_SECRET,
      })
    );
  }

  setRefreshedTokenHeaders(response) {
    const { access_token, refresh_token, token_type, expires_in } = response || {};

    if (access_token && refresh_token && token_type && expires_in) {
      this._setExpirationTime(expires_in);
      LocalStorageUtils.updateHeaders({
        access_token,
        refresh_token,
        token_type,
      });
      CookieUtils.updateHeaders({
        access_token,
        refresh_token,
        token_type,
      });
    } else {
      throw new Error('Invalid response from refresh token verification');
    }
  }

  refreshAccessToken() {
    const refresh_token = localStorage.getItem('refresh_token');

    this.refreshAttempt = new Promise((resolve, reject) => {
      if (!refresh_token) {
        return reject('No refresh token');
      }

      this.store
        .dispatch(
          this.api.post('oauth/access_token', {
            //issueToken in diglet Oauth controller
            refresh_token,

            grant_type: 'refresh_token',
            client_id: process.env.CLIENT_ID,
            client_secret: process.env.CLIENT_SECRET,
          })
        )
        .then(({ response }) => {
          const { access_token, refresh_token, token_type, expires_in } = response;

          this._setExpirationTime(expires_in);
          LocalStorageUtils.updateHeaders({
            access_token,
            refresh_token,
            token_type,
          });
          CookieUtils.updateHeaders({
            access_token,
            refresh_token,
            token_type,
          });

          return resolve(access_token);
        })
        .catch((err) => {
          this.logout();
          // @ts-ignore
          return reject('Refresh Failed', err);
        });
    });
  }

  async handleTokenExpiration(operation) {
    const refresh_token = localStorage.getItem('refresh_token');

    if (!refresh_token) {
      this.logout();
      throw new Error('No refresh token');
    }

    const requestKey = operation.toKey();
    this._pendingRequests.push(requestKey);

    if (this._pendingRequests.length === 1) {
      this.refreshAccessToken();
    }

    try {
      const access_token = await this.refreshAttempt;
      const token_type = localStorage.getItem('token_type');

      operation.setContext({
        headers: {
          Authorization: `${token_type} ${access_token}`,
        },
      });

      this._pendingRequests = without(this._pendingRequests, requestKey);

      // Remove the Promise for the next time the token needs reseting
      if (this._pendingRequests.length === 0) {
        this.refreshAttempt = null;
      }

      return operation;
    } catch (err) {
      this.logout();
      // @ts-ignore
      throw new Error('Refresh Failed:', err);
    }
  }

  checkScopes(username, allowedScopes, response) {
    const { expires_in, scopes } = response;

    // User doesn't have ANY scopes OR
    // The intersection of the user scopes and the allowed scopes
    // isn't less than the length of both combined.
    //
    // If they have ONE of the scopes, the length of the union
    // would be less than the 2 arrays combined.
    //
    // [1,2] union [2,3] === [1,2,3]
    // It's not the case that [1,2,3].length >= [1,2].length + [2,3].length
    if (allowedScopes) {
      if (!scopes || union(scopes, allowedScopes).length >= scopes.length + allowedScopes.length) {
        throw { error_description: 'Invalid Credentials.' };
      }
    }

    this._setExpirationTime(expires_in);
    LocalStorageUtils.saveHeaders(response, username); //does not save uuid
    CookieUtils.saveHeaders(response, username);
  }

  signIn(username, password, allowedScopes, params = {}) {
    return this.store
      .dispatch(
        this.api.post('oauth/access_token', {
          //issueToken in diglet Oauth controller
          username,
          password,

          grant_type: 'password',
          client_id: process.env.CLIENT_ID,
          client_secret: process.env.CLIENT_SECRET,
          scope: process.env.CLIENT_SCOPE,
          ...params,
        })
      )
      .then(({ response }) => {
        this.checkScopes(username, allowedScopes, response);
        return this.getMe(response.scopes);
      })
      .catch((err) => {
        throw err;
      });
  }

  logout() {
    const access_token = localStorage.getItem('access_token');

    if (!access_token) {
      this._clearSession();
      return;
    }

    this.store
      .dispatch(
        this.api.delete(
          'oauth/session', //revokeSession in diglet Oauth controller
          {},
          {
            headers: LocalStorageUtils.getAuthorizationHeader(),
          }
        )
      )
      .then(() => {
        this._clearSession();
      })
      .catch(() => {
        this._clearSession();
      });
  }

  forgotPassword(email, redirect) {
    return this.store
      .dispatch(this.api.get(`v1/users/passwords/forgot?username=${email}&app_url=${redirect}`))
      .then(({ status }) => {
        if (status === 204) {
          return 'Reset Password Email Sent';
        }
      })
      .catch((err) => {
        throw err;
      });
  }

  resetPassword(username, password, token) {
    return this.store
      .dispatch(
        this.api.post('v1/users/passwords/reset', {
          username,
          password,
          token,
        })
      )
      .then(({ status }) => {
        if (status === 204) {
          return 'Password has been successfully reset';
        }
      })
      .catch((err) => {
        throw err;
      });
  }

  createMFAAccessToken(mfaToken) {
    return this.store
      .dispatch(
        this.api.post(
          'v1/mfa/challenge',
          {
            mfa_token: mfaToken,
          },
          {
            headers: LocalStorageUtils.getAuthorizationHeader(),
          }
        )
      )
      .then(({ status, response }) => {
        if (status === 200) {
          return response.data.challenge;
        }
      })
      .catch((err) => {
        throw err;
      });
  }

  mfaRegisterDevice(mfa_type_id, payload = {}) {
    return this.store
      .dispatch(
        this.api.post(
          'v1/me/mfa-devices',
          { mfa_type_id: mfa_type_id, payload: payload },
          { headers: LocalStorageUtils.getAuthorizationHeader() }
        )
      )
      .then(({ response }) => {
        return response.data;
      })
      .catch((err) => {
        throw err;
      });
  }

  mfaVerifyDevice(mfa_device_id, payload) {
    return this.store
      .dispatch(
        this.api.post(
          'v1/me/mfa-devices/' + mfa_device_id + '/verify',
          {
            payload: payload,
          },
          {
            headers: LocalStorageUtils.getAuthorizationHeader(),
          }
        )
      )
      .then(({ response }) => {
        return response.data;
      })
      .catch((err) => {
        throw err;
      });
  }

  mfaSignIn(challenge, mfaToken, username, password, allowedScopes) {
    return this.store
      .dispatch(
        this.api.post('oauth/access_token', {
          //issueToken in diglet Oauth controller
          username,
          password,
          mfa_token: mfaToken,
          challenge_response: {
            code: challenge,
          },

          grant_type: 'mfa_challenge_response',
          scope: process.env.CLIENT_SCOPE,
          client_id: process.env.CLIENT_ID,
          client_secret: process.env.CLIENT_SECRET,
        })
      )
      .then(({ response }) => {
        this.checkScopes(username, allowedScopes, response);
        return this.getMe(response.scopes); //show function in diglet MeConroller, returns user data
      })
      .catch((err) => {
        throw err;
      });
  }

  getMe(scopes = []) {
    return this.store
      .dispatch(
        this.api.get('v1/me', {
          headers: LocalStorageUtils.getAuthorizationHeader(),
        })
      )
      .then(({ status, response }) => {
        if (status === 200) {
          localStorage.setItem('uuid', response.data.id);
          this.dispatchVerification(true);
          return { ...response, scopes };
        }
      })
      .catch((err) => {
        throw err;
      });
  }

  getUserInfo(user_id) {
    return fetch(this.apiUrl + '/v1/users/' + user_id + '/info', {
      headers: generateRequestHeaders(),
    }).then((response) => {
      if (response.status === 200) {
        return response.json();
      }
      // @ts-ignore
      throw Error(response.status);
    });
  }

  // Get user roles
  getUserRoles() {
    return fetch(this.apiUrl + '/v1/roles', {
      headers: generateRequestHeaders(),
    }).then((response) => {
      if (response.status === 200) {
        return response.json();
      }
      // @ts-ignore
      throw Error(response.status);
    });
  }

  // Updates a user's roles
  updateUserRoles(user_id, roles) {
    return fetch(this.apiUrl + '/v1/users/' + user_id + '/roles', {
      headers: {
        ...generateRequestHeaders(),
        'Content-Type': 'application/json',
      },
      method: 'PUT',
      body: JSON.stringify({ roles: roles }),
    }).then((response) => {
      if (response.status === 200) {
        return response.json();
      }
      // @ts-ignore
      throw Error(response.status);
    });
  }

  // Revoke a users tokens
  revokeUserTokens(user_id) {
    return fetch(this.apiUrl + '/v1/users/' + user_id + '/revoke-tokens', {
      headers: {
        ...generateRequestHeaders(),
        'Content-Type': 'application/json',
      },
      method: 'PUT',
    }).then((response) => {
      if (response.status === 204) {
        return true;
      }
      // @ts-ignore
      throw Error(response.status);
    });
  }

  // Reactivate a "soft deleted" user
  reactivateUser(user_id) {
    return fetch(this.apiUrl + '/v1/users/' + user_id + '/reactivate', {
      headers: generateRequestHeaders(),
      method: 'PUT',
    }).then((response) => {
      return response.status === 204;
    });
  }

  removeUserMfa(user_id) {
    return fetch(this.apiUrl + '/v1/users/' + user_id + '/mfa-devices', {
      headers: generateRequestHeaders(),
      method: 'DELETE',
    }).then((response) => {
      return response.status === 204;
    });
  }
}
