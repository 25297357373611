// AUTOMATICALLY GENERATED. DO NOT EDIT

import * as Types from '../../../../../graphql-codegen/types';

import { gql } from '@apollo/client';
export type PagingFragmentFragment = {
  __typename?: 'PagingData';
  page?: number | null;
  pages?: number | null;
  total?: number | null;
  limit?: number | null;
};

export const PagingFragmentFragmentDoc = gql`
  fragment pagingFragment on PagingData {
    page
    pages
    total
    limit
  }
`;
