import React, { useEffect } from 'react';

import { useLazyGetUserIdentifiers } from '../hooks';
import { AdminUserContext } from './AdminUserContext';
import { setUserIdentifiers } from 'actions/auth/actions';
import LoadingPage from 'components/layout/Page/LoadingPage';
import Auth from 'services/auth';
import { useActionDispatch, useStateSelector } from 'shared/hooks';
import LocalStorageUtils from 'utils/localStorage';

/**
 * Provider responsible for verifying a user's access token, fetching the user from rotom,
 * and setting that user in the redux store.
 */
export const AdminUserContextProvider = ({ children }) => {
  const [lazyGetUserQuery, { loading }] = useLazyGetUserIdentifiers();

  const userId = LocalStorageUtils.getUserId();

  const { dispatch } = useActionDispatch();
  const { initialized, user } = useStateSelector((state) => ({
    initialized: !!state?.auth?.initialized,
    user: state?.auth?.userIdentifiers?.user,
  }));

  useEffect(() => {
    const initializeAdminUser = async () => {
      try {
        await Auth.verifyToken();

        /**
         * NOTE: This might be entirely unnecessary, since the Redux store is already initialized
         * with user information on sign-in. Worth further investigation, but leaving in as a copy
         * from Initialize.tsx.
         */
        if (userId) {
          const response = await lazyGetUserQuery({ variables: { id: userId } });
          if (response?.data) {
            dispatch(setUserIdentifiers(response.data));
          }
        }
      } catch (e) {
        console.error(e);
        throw e;
      }
    };

    initializeAdminUser();
  }, []);

  if (!initialized) {
    return <LoadingPage />;
  }

  return (
    <>{initialized && <AdminUserContext.Provider value={{ user, loading }}>{children}</AdminUserContext.Provider>}</>
  );
};
