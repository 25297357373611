import { useEffect } from 'react';
import mixpanel, { type Dict } from 'mixpanel-browser';

import { ddGlobalSessionId } from 'utils/telemetry';
import { APP_TRACKING_EVENTS, USER_TRACKING_EVENTS } from './events';
import LocalStorageUtils from '../localStorage';

import type { AdminUser } from 'modules/Admin/shared/context/AdminUserContext';

/**
 * Hook to initialize user-tracking dependency.
 */
export const useAndInitializeUserTracking = () => {
  const isProductionEnv = process.env.APP_ENV === 'production';

  useEffect(() => {
    if (process.env.MIXPANEL_TOKEN) {
      mixpanel.init(process.env.MIXPANEL_TOKEN, {
        /**
         * Comment copied from Atlas repo:
         *
         * Default expiration is 1 year, but we want to shorten that, albeit this is arbitrary as
         * well. It's easier to start short and go longer that it is to start long and go shorter.
         */
        cookie_expiration: 7,
        debug: process.env.APP_ENV === 'dev',
        secure_cookie: isProductionEnv,
      });

      /**
       * Register these properties only if in non-production environments so that: a) Our
       * production events aren't polluted with them, and b) The events in our staging dashboard,
       * which receives ALL non-production events, can be easily distinguished between each
       * non-prod application.
       */
      if (!isProductionEnv) {
        mixpanel.register({
          is_non_production: true,
          environment: process.env.APP_ENV,
        });
      }

      mixpanel.register({ sh_magic_id: ddGlobalSessionId.getSessionId() });
    }
  }, []);
};

/**
 * Function to set user identity for tracking purposes.
 */
export const setUserTrackingIdentity = (user: AdminUser) => {
  if (!process.env.MIXPANEL_TOKEN) return;

  if (user?.id) {
    mixpanel.identify(user.id);
  }
};

/**
 * DO NOT USE. This is a wrapper around the base tracking function, mainly to abstract the tracking
 * of event properties that should be present in every event. Use trackUserEvent below instead.
 */
const track = (event: string, properties?: Dict) => {
  if (!process.env.MIXPANEL_TOKEN) return;

  mixpanel.track(event, {
    ...properties,
    roles: LocalStorageUtils.getUserScopes(),
    $current_url: window.location.href,
    $referrer: document.referrer,
  });
};

export const trackAppEvent = {
  // DO NOT USE `pageViewed`. Page views are auto-logged on router changes in Initialize
  pageViewed: (page, properties?: {}) => track(APP_TRACKING_EVENTS.PAGE_VIEWED, { page, ...properties }),
};

export const trackUserEvent = {
  buttonClicked: (page, type, properties = {}) =>
    track(USER_TRACKING_EVENTS.BUTTON_CLICKED, { page, type, ...properties }),
  linkClicked: (page, type, value, properties = {}) =>
    track(USER_TRACKING_EVENTS.LINK_CLICKED, { page, type, value, ...properties }),
  modalOpened: (page, type, properties = {}) => track(USER_TRACKING_EVENTS.MODAL_OPENED, { page, type, ...properties }),
  modalClosed: (page, type, properties = {}) => track(USER_TRACKING_EVENTS.MODAL_CLOSED, { page, type, ...properties }),
  formSubmitted: (page, type, properties = {}) =>
    track(USER_TRACKING_EVENTS.FORM_SUBMITTED, { page, type, ...properties }),
  notificationViewed: (page, type, properties = {}) =>
    track(USER_TRACKING_EVENTS.NOTIFICATION_VIEWED, { page, type, ...properties }),
  filterChange: (page, type, props) => track(USER_TRACKING_EVENTS.FILTER_CHANGE_APPLIED, { page, type, ...props }),
};
