import { useQuery } from '@apollo/client';
import { GetAppointmentSlotsDocument } from '../graphql/queries/getAppointmentSlots.generated';

import type { QueryHookOptions } from '@apollo/client';
import {
  GetAppointmentSlotsQuery,
  GetAppointmentSlotsQueryVariables,
} from '../graphql/queries/getAppointmentSlots.generated';

const useGetAppointmentSlots = (
  baseOptions?: QueryHookOptions<GetAppointmentSlotsQuery, GetAppointmentSlotsQueryVariables>
) => {
  return useQuery<GetAppointmentSlotsQuery, GetAppointmentSlotsQueryVariables>(GetAppointmentSlotsDocument, {
    ...baseOptions,
  });
};

export default useGetAppointmentSlots;
