import React, { useEffect } from 'react';
import { Container, Text, VStack } from '@springcare/sh-component-library';
import { useRouter } from 'next/router';
import { datadogRum } from '@datadog/browser-rum';

import LandingPageWrapper from 'components/templates/Landing/PageWrapper/LandingPageWrapper';

export type ErrorPagePropsType = {
  errorMessage: React.ReactNode;
  hideErrorPage: React.Dispatch<React.SetStateAction<boolean>>;
};

/**
 * Fallback UI to render inside the ErrorBoundary.
 */
export const ErrorPage = ({ errorMessage, hideErrorPage }) => {
  const [countdown, setCountdown] = React.useState(5000);

  const router = useRouter();

  useEffect(() => {
    const timerId = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1000);
    }, 1000);

    if (countdown === 0) {
      clearTimeout(timerId);

      if (process.env.APP_ENV !== 'dev') {
        router.replace('/admin').then(() => hideErrorPage());
      }
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [countdown]);

  return (
    <LandingPageWrapper>
      <Container maxW="2xl" centerContent>
        <VStack h="50vh" justifyContent="center" gap={4}>
          <Text textStyle="heading-display">{errorMessage}</Text>
          <Text textStyle="body-medium-regular">
            {`Sorry for the inconvenience! The error has been logged for further investigation, and you will be redirected
            in ${countdown / 1000} second(s).`}
          </Text>
        </VStack>
      </Container>
    </LandingPageWrapper>
  );
};

export type ErrorBoundaryPropsType = {
  children: React.ReactNode;
  fallback?: React.ReactNode;
};

export type ErrorBoundaryStateType = {
  hasError: boolean;
};

export class ErrorBoundary extends React.Component<ErrorBoundaryPropsType, ErrorBoundaryStateType> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };

    this.hideErrorPage = this.hideErrorPage.bind(this);
  }

  static getDerivedStateFromError() {
    // Updated state so the next render will show the fallback UI
    return { hasError: true };
  }

  // https://docs.datadoghq.com/real_user_monitoring/browser/collecting_browser_errors/?tab=npm#react-error-boundaries-instrumentation
  componentDidCatch(error, errorInfo) {
    const renderingError = new Error(error.message);
    renderingError.name = `ReactErrorBoundaryError`;
    renderingError.stack = errorInfo.componentStack;
    renderingError.cause = error;

    datadogRum.addError(renderingError);
  }

  hideErrorPage() {
    this.setState({ hasError: false });
  }

  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      return this.props.fallback ? (
        this.props.fallback
      ) : (
        <ErrorPage errorMessage={'Oops, an unexpected error occurred'} hideErrorPage={this.hideErrorPage} />
      );
    } else {
      return this.props.children;
    }
  }
}

export default ErrorBoundary;
