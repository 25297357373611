import { gql } from '@apollo/client';

import { userInfo } from '../../fragments/user';
import paging from '../../fragments/paging';

const getUsers = gql`
  query getUsers(
    $active: Boolean
    $roles: [UserRoleEnumType]
    $name_or_email: String
    $offset: Int
    $limit: Int = 30
    $created_after: Date
    $created_before: Date
  ) {
    users(
      active: $active
      roles: $roles
      name_or_email: $name_or_email
      offset: $offset
      limit: $limit
      created_after: $created_after
      created_before: $created_before
    ) {
      data {
        ...userInfo
      }
      paging {
        ...paging
      }
    }
  }
  ${userInfo}
  ${paging}
`;

export default getUsers;
