//

import React from 'react';
import classnames from 'classnames';

import styles from './styles.module.scss';

const Standard = (props) => (
  <div
    className={classnames(styles.standard, {
      [styles.notCenter]: !props.center,
      [styles.paddingTopHeader]: props.addPaddingHeader,
    })}
  >
    {props.children}
  </div>
);

Standard.defaultProps = {
  center: true,
};

export default Standard;
