import { gql } from '@apollo/client';

const getUserAppointments = gql`
  query getUserAppointments($id: ID!) {
    user(id: $id) {
      id
      calendar {
        id
        appointments {
          id
          start_at
          end_at
          duration
          kind
          paid
          status
          attended
          bookings {
            id
            avatar
            name
          }
        }
      }
    }
  }
`;

export default getUserAppointments;
