import { useLazyQuery } from '@apollo/client';
import { GetCustomersDocument } from '../graphql/queries/getCustomers.generated';

import type { LazyQueryHookOptions } from '@apollo/client';
import type { GetCustomersQuery, GetCustomersQueryVariables } from '../graphql/queries/getCustomers.generated';

export const useLazyGetCustomers = (
  baseOptions?: LazyQueryHookOptions<GetCustomersQuery, GetCustomersQueryVariables>
) => {
  return useLazyQuery<GetCustomersQuery, GetCustomersQueryVariables>(GetCustomersDocument, { ...baseOptions });
};
