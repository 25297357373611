import Cookies from 'universal-cookie';

// Cookies set to all subdomains
const buildCookies = () =>
  new Cookies(null, {
    path: '/',
    domain: window.location.host.includes('localhost')
      ? undefined
      : `.${window.location.host.split('.').slice(-2).join('.')}`,
  });

const CookieUtils = {
  // Save cookies for shared admin-portal/standalone compass auth
  saveHeaders: (headers, uid) => {
    const { access_token, refresh_token, scopes, token_type } = headers;
    const cookies = buildCookies();
    cookies.set('access_token', access_token);
    cookies.set('refresh_token', refresh_token);
    cookies.set('scopes', scopes);
    cookies.set('token_type', token_type);
    cookies.set('uid', uid);
  },

  updateTokenExpiration(access) {
    const cookies = buildCookies();
    cookies.set('access_token_expires_at', access);
  },

  updateHeaders: (headers) => {
    const cookies = buildCookies();
    for (const header in headers) {
      cookies.set(header, headers[header]);
    }
  },

  // Clears all headers from cookies for shared admin-portal/standalone compass auth
  clearHeaders: () => {
    const cookies = buildCookies();
    cookies.remove('access_token');
    cookies.remove('refresh_token');
    cookies.remove('access_token_expires_at');
    cookies.remove('token_type');
    cookies.remove('scopes');
    cookies.remove('uid');
    cookies.remove('uuid');
  },
};

export default CookieUtils;
