import React, { useState } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import includes from 'lodash/includes';
import { useRouter } from 'next/router';

import { Header } from './Header';
import { SideMenu } from './SideMenu';
import styles from './styles.module.scss';
import { MobileSideMenu } from './MobileSideMenu';
import { useToggle } from 'components/hooks';

interface SideMenuCallbackData {
  open: boolean;
}

const Navigation = ({ children, isLoggedInAndInitialized }) => {
  const [sideMenuCallbackData, changeSideMenuCallbackData] = useState({ open: true });
  const [mobileSideMenuIsOpen, toggleMobileSideMenu] = useToggle(false);
  const router = useRouter();

  const showSideMenu = isLoggedInAndInitialized;

  const inCompass = includes(router.asPath, 'compass');

  // This callback will allow for the navigation component to listen for changes in
  // the child SideNav, so that it can change the padding styles accordingly
  const sideMenuCallback = (sideMenuData: SideMenuCallbackData) => changeSideMenuCallbackData(sideMenuData);

  return inCompass ? (
    <>{children}</>
  ) : (
    <div data-testid="navigation" className={styles.navigation}>
      <Header mobileSideMenuIsOpen={mobileSideMenuIsOpen} toggleMobileSideMenu={toggleMobileSideMenu} />
      {showSideMenu && (
        <>
          <SideMenu callback={sideMenuCallback} />
          <MobileSideMenu open={mobileSideMenuIsOpen} />
        </>
      )}
      <div
        className={classnames(styles.childrenWrapper, {
          [styles.openSideNav]: sideMenuCallbackData.open,
          [styles.noLeftPadding]: !showSideMenu,
        })}
      >
        {children}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isLoggedInAndInitialized: state.auth.isLoggedIn && state.auth.initialized,
});

export default connect(mapStateToProps)(Navigation);
