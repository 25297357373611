//

import { ADD_NOTIFICATION, DISMISS_NOTIFICATION, MOUNT_NOTIFICATION, REMOVE_NOTIFICATION } from './types';

export function addNotification(text, color) {
  return (dispatch) => {
    const timestamp = Date.now();

    dispatch({
      type: ADD_NOTIFICATION,
      text,
      color,
      timestamp,
    });
  };
}

export function dismissNotification(timestamp) {
  return {
    type: DISMISS_NOTIFICATION,
    timestamp,
  };
}

export function mountNotification(timestamp) {
  return {
    type: MOUNT_NOTIFICATION,
    timestamp,
  };
}

export function removeNotification(timestamp) {
  return {
    type: REMOVE_NOTIFICATION,
    timestamp,
  };
}
