//

import flow from 'lodash/fp/flow';
import { setIn } from 'utils/immutability';
import { AUTH_VERIFIED_TOKEN, AUTH_INITIALIZED, AUTH_USER_IDENTIFIERS_LOADED } from 'actions/auth/types';

export const initialState = {
  isTokenVerified: false,
  isLoggedIn: false,
  initialized: false,
};

export const logoutState = {
  isTokenVerified: true,
  isLoggedIn: false,
  initialized: true,
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_VERIFIED_TOKEN:
      return flow(setIn('isTokenVerified', action.verified), setIn('isLoggedIn', action.valid))(state);

    case AUTH_INITIALIZED:
      return setIn('initialized', true, state);

    case AUTH_USER_IDENTIFIERS_LOADED:
      return {
        ...state,
        userIdentifiers: action.userIdentifiers || {},
      };

    default:
      return state;
  }
};

export default auth;
